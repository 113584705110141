<template>
  <div>
    <b-tabs>
      <b-tab
        active
        :title="$t('organizations.scratch')"
        content-class="mt-2"
        class="mt-2"
        title-link-class="px-0 font-weight-bolder"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="mt-50"
            @submit.prevent="handleSubmit(handleCreateItem)"
          >
            <!-- NAME -->
            <validation-provider
              #default="validationContext"
              name=" "
              rules="required"
            >
              <b-form-group
                :label="$t('backoffice.settings.community-name') + ' *'"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="itemInput.name[selected]"
                  type="text"
                  maxlength="150"
                  :disabled="isSaving"
                  :placeholder="$t('form-create-item.name-placeholder')"
                  rules="required"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <template v-if="showExtended">
              <!-- HEADLINE -->
              <b-form-group
                v-if="!fromOnboarding"
                :label="$t('backoffice.settings.headline')"
                label-for="headline"
              >
                <b-form-input
                  id="headline"
                  v-model="itemInput.headline[selected]"
                  type="text"
                  maxlength="150"
                  :disabled="isSaving"
                  :placeholder="$t('form-create-item.headline-placeholder')"
                />
              </b-form-group>

              <b-row v-if="!fromOnboarding">
                <!-- Modality -->
                <b-col
                  v-if="['events', 'courses'].includes(itemType)"
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="type">{{
                      $t("events.actions.modality-options")
                    }}</label>
                    <v-select
                      id="type"
                      v-model="modalityOption"
                      :options="modalityOptions"
                      :reduce="(modality) => modality.code"
                      label="modality"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <!-- Accessibility -->
                <b-col v-if="hasAccessibility" cols="12" md="6">
                  <b-form-group>
                    <label for="type">{{
                      $t("events.actions.accessibility.title")
                    }}</label>
                    <v-select
                      id="type"
                      v-model="accessibilityOption"
                      :clearable="false"
                      :reduce="(accessibility) => accessibility.code"
                      label="accessibility"
                      :options="accessibilityOptions"
                    />
                  </b-form-group>
                </b-col>
                <!-- Type -->
                <b-col v-if="typeOptions" cols="12" md="6">
                  <b-form-group>
                    <label for="type">{{
                      $t("events.actions.type-options")
                    }}</label>
                    <v-select
                      id="type"
                      v-model="selectedTypeOption"
                      :clearable="false"
                      :reduce="(type) => type.code"
                      :options="typeOptions"
                      label="type"
                    />
                  </b-form-group>
                </b-col>
                <!-- Place Publish -->
                <b-col
                  v-if="whereIsPublished"
                  cols="12"
                  :md="!typeOptions && itemType === 'events' ? '12' : '6'"
                >
                  <b-form-group>
                    <label for="place">{{
                      $t("events.actions.place-publish")
                    }}</label>
                    <v-select
                      id="place"
                      v-model="placePublishOption"
                      :clearable="false"
                      :reduce="(place) => place.code"
                      :options="placePublishOptions"
                      label="place"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" :md="itemType === 'courses' ? '6' : '12'">
                  <b-form-group
                    v-if="placePublishOption === 0"
                    :label="$t('events.actions.template.title')"
                    label-for="template"
                  >
                    <v-select
                      id="template"
                      v-model="layoutTemplate"
                      :options="layoutTemplates"
                      :reduce="(template) => template.key"
                      label="name"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="itemType != 'organizations'"
                  cols="12"
                  md="6"
                  class="zindex-1"
                >
                  <b-form-group
                    :label="`${$t(
                      'calendar.meeting-form.start-date.label'
                    )}   (${$t('events.event-details.local-time')})`"
                  >
                    <VueCtkDateTimePicker
                      v-model="itemInput.startDate"
                      :locale="locale"
                      format="YYYY-MM-DD HH:mm"
                      :label="$t('datepicker.select-label')"
                      color="var(--primary)"
                      no-button-now
                      first-day-of-week:-1-
                      right
                      button-color="var(--primary)"
                      @input="setStart"
                    />
                    <!--           <small v-if="invalidStartDate" class="text-danger">
                      The field is required
                    </small> -->
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="itemType != 'organizations'"
                  cols="12"
                  md="6"
                  class="zindex-3"
                >
                  <b-form-group
                    :label="`${$t(
                      'calendar.meeting-form.end-date.label'
                    )}   (${$t('events.event-details.local-time')})`"
                  >
                    <VueCtkDateTimePicker
                      v-model="itemInput.endDate"
                      :locale="locale"
                      format="YYYY-MM-DD HH:mm"
                      :label="$t('datepicker.select-label')"
                      color="var(--primary)"
                      no-button-now
                      button-color="var(--primary)"
                      :min-date="itemInput.startDate"
                      @input="setEnd"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-alert
                    v-if="
                      itemInput.startDate &&
                      itemInput.endDate &&
                      itemInput.startDateUnix > itemInput.endDateUnix
                    "
                    show
                    variant="danger"
                    class="error-notification"
                  >
                    {{ $t("datepicker.error-message") }}
                  </b-alert>
                </b-col>
                <b-col class="12" md="12">
                  <!-- Room -->
                  <b-form-group
                    v-if="modalityOption > 0 && itemType === 'events'"
                    :label="$t('organizations.edit.rooms.label')"
                    label-for="profile-room"
                  >
                    <v-select
                      v-model="itemInput.roomKey"
                      :options="roomOptions"
                      :reduce="(x) => x.key"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="12" md="12">
                  <!-- Locations -->
                  <b-form-group
                    v-if="
                      modalityOption > 0 &&
                      itemType === 'events' &&
                      itemInput.roomKey == null
                    "
                    :label="$t('organizations.edit.locations.label')"
                    label-for="profile-location"
                  >
                    <places-map-select
                      v-model="itemInput.locations"
                      :placeholder="
                        $t('organizations.edit.locations.placeholder')
                      "
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="!fromOnboarding">
                <b-col color="12" :md="placePublishOption === 0 ? '6' : '12'">
                  <b-form-group
                    :label="
                      placePublishOption === 0
                        ? $t('media.form.file.thumbnail')
                        : $t('form-create-item.image')
                    "
                    label-for="logo"
                    class="d-inline-block w-100"
                  >
                    <file-preview
                      v-if="itemInput.imageURL"
                      :src="getImageResource(itemInput.imageURL)"
                      :placeholder-image="avatarPlaceholder"
                      mime-type="image"
                      cancelable
                      @cancel="itemInput.imageURL = null"
                    />
                    <file-upload
                      v-model="image"
                      type="image"
                      cancelable
                      :placeholder="$t('form-create-item.image-button')"
                      :drop-placeholder="$t('form-create-item.drop-placeholder')"
                      @cancel="image = null"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="placePublishOption === 0" color="12" md="6">
                  <b-form-group label="Banner" label-for="banner">
                    <file-preview
                      v-if="itemInput.bannerURL"
                      :src="getImageResource(itemInput.bannerURL)"
                      :placeholder-image="avatarPlaceholder"
                      mime-type="image"
                      cancelable
                      @cancel="itemInput.bannerURL = null"
                    />
                    <file-upload
                      v-model="bannerURL"
                      type="image"
                      cancelable
                      :placeholder="$t('form-create-item.image-button')"
                      :drop-placeholder="$t('form-create-item.drop-placeholder')"
                      @cancel="bannerURL = null"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="placePublishOption === 1" cols="12">
                  <b-form-group
                    :label="$t('events.actions.link')"
                    label-for="register-link"
                  >
                    <b-form-input
                      id="register-link"
                      v-model="itemInput.registrationURL"
                      type="url"
                      autofocus
                      trim
                      :placeholder="$t('events.actions.link-placeholder')"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="placePublishOption === 2" cols="12">
                  <b-form-group
                    :label="$t('events.actions.link')"
                    label-for="join-link"
                  >
                    <b-form-input
                      id="join-link"
                      v-model="itemInput.joinURL"
                      type="url"
                      autofocus
                      trim
                      :placeholder="$t('events.actions.link-placeholder')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>

            <!-- IS PUBLISHED -->
            <b-form-group v-if="!fromOnboarding">
              <b-checkbox
                id="published"
                v-model="itemInput.isPublished"
                class="ml-25"
              >
                {{ $t("form.actions.publish") }}
              </b-checkbox>
            </b-form-group>

            <div class="float-right mb-50">
              <!-- Missing to implement BE -->
              <b-button
                variant="primary"
                class="float-right"
                :disabled="isSaving"
                type="submit"
              >
                <span v-if="isSaving" class="d-flex">
                  <b-spinner
                    class="mr-1"
                    small
                    variant="white"
                    label="Loading..."
                  />
                  {{ $t("form.actions.save") }}
                </span>
                <span v-else>{{ $t("form.actions.save") }}</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-tab>

      <b-tab
        :title="$t('organizations.import-from')"
        content-class="mt-2"
        class="mt-2"
        title-link-class="px-0 font-weight-bolder  ml-2"
      >
        <div>
          <b-alert show variant="danger">
            <feather-icon size="14" icon="AlertTriangleIcon" />
            {{ $t("organizations.limit") }}
          </b-alert>
        </div>
        <div
          class="d-flex flex-wrap align-items-center justify-content-between my-1"
        >
          <b-button
            variant="link"
            class="p-0 mt-50 pointer font-weight-bold"
            @click="uploadFileVisible = !uploadFileVisible"
          >
            {{ $t("organizations.import-csv.title") }}
          </b-button>
          <b-badge
            variant="light-primary"
            class="ml-25 mt-50 font-weight-normal pointer"
            @click="downloadCsv()"
          >
            <feather-icon icon="DownloadIcon" class="mr-25" />{{
              $t("download.csv")
            }}
          </b-badge>
        </div>
        {{ message }}
        <div v-if="uploadFileVisible">
          <file-upload
            v-model="csvFile"
            type="csv"
            class="w-100 my-1"
            cancelable
            :placeholder="$t('media.form.file.placeholder')"
            :drop-placeholder="$t('media.form.file.drop-placeholder')"
          />
        </div>
        <b-button variant="primary" class="float-right" @click="saveCsv">
          {{ $t("form.actions.save") }}
        </b-button>
      </b-tab>
    </b-tabs>

    <loading-modal
      v-model="isLoadingButton"
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      is-upload
    />

    <error-modal
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      :title="$t('appsumo.errors-modal.title')"
      :subtitle="$t('appsumo.errors-modal.subtitle')"
    />
  </div>
</template>

<script>
// import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import { required } from "@validations";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import FileTypes from "@/@core/constants/FileTypes";
import PlacesMapSelect from "@core/components/places-map-input/PlacesMapSelect.vue";
import FileUpload from "@core/components/files/FileUpload.vue";
import FilePreview from "@core/components/files/FilePreview.vue";
import { MainType } from "@copernicsw/community-common";
import LoadingModal from "@core/components/modal/LoadingModal.vue";
import Ripple from "vue-ripple-directive";
import ErrorModal from "@core/components/modal/ErrorModal.vue";

export default {
  name: "MembershipEventCreateModal",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    FilePreview,
    PlacesMapSelect,
    FileUpload,
    LoadingModal,
    ErrorModal,
  },
  directives: {
    Ripple,
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    typeOptions: { type: Array, required: false },
    itemType: { type: String, required: true },
    canCreateOrganization: { type: Boolean, default: false },
    selected: {
      type: String,
      default: "",
    },
    fromOnboarding: { type: Boolean, default: false },
    showExtended: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: true,
      isLimit: "",
      message: "",
      isSaving: false,
      invalidStartDate: false,
      invalidEndDate: false,
      isLoadingButton: false,
      isLoadingNextPage: false,
      hasMore: false,
      isModalVisible: false,
      uploadFileVisible: false,
      currentPage: 0,
      bannerURL: null,
      csvFile: null,
      selectedFilter: "upcoming",
      csvTemplateOrganizations: [
        [
          "Name of the organization 1",
          "Headline of the organization 1",
          "Example description for the organization 1",
          "email1@example.com",
          "123123123",
          "www.example1.org",
        ],
        [
          "Name of the organization 2",
          "Headline of the organization 2",
          "Example description for the organization 2",
          "email2@example.com",
          "456456456",
          "www.example2.org",
        ],
      ],
      itemInput: {
        roomKey: null,
        showCreated: true,
        isPublished: true,
        startDate: null,
        startDateUnix: null,
        endDate: null,
        endDateUnix: null,
        capacity: null,
        headline: {},
        name: {},
      },
      formError: null,
      results: [],
      selectedTypeOption: 27413,
      placePublishOption: 0,
      required,
      modalityOption: 0,
      imageSrc: null,
      locations: [],
      image: null,
      accessibilityOption: this.itemType === "organizations" ? 2 : 0,
      templates: [
        { name: this.$t("events.actions.template.no-template"), key: 0 },
        { name: this.$t("events.actions.template.default-template"), key: 1 },
      ],
      layoutTemplate: 1,
      accessibilityOptions: [
        {
          code: 0,
          accessibility: this.$t(
            "events.actions.accessibility.without-request"
          ),
        },
        {
          code: 1,
          accessibility: this.$t("events.actions.accessibility.with-request"),
        },
        {
          code: 2,
          accessibility: this.$t("events.actions.accessibility.invitation"),
        },
      ],
      modalityOptions: [
        { code: 0, modality: this.$t("events.event-details.online") },
        { code: 1, modality: this.$t("events.event-details.hybrid") },
        { code: 2, modality: this.$t("events.event-details.on-site") },
      ],
      placePublishOptions: [
        { code: 0, place: this.$t("events.event-details.here-place") },
        { code: 1, place: this.$t("events.event-details.external-place") },
        { code: 2, place: this.$t("events.event-details.hybrid-place") },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    membersError() {
      return this.$store.getters.membersError;
    },
    rooms() {
      return this.$store.getters.rooms?.unpaginated;
    },
    roomOptions() {
      return this.rooms.map((x) => ({
        label: x.name,
        key: x.key,
      }));
    },
    FileTypes() {
      return FileTypes;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    itemsData() {
      return this.$store.getters.layoutTemplates;
    },
    isMobile() {
      return window.innerWidth < 576;
    },
    layoutTemplates() {
      if (this.itemsData?.unpaginated?.length > 0) {
        return [...this.templates, ...this.itemsData.unpaginated];
      }
      return this.templates;
    },
    hasAccessibility() {
      return !["organizations", "services"].includes(this.itemType);
    },
    set() {
      return !["organizations"].includes(this.itemType);
    },
    redirectAfterCreate() {
      return !["organizations"].includes(this.itemType);
    },
    whereIsPublished() {
      return !["organizations", "services"].includes(this.itemType);
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  methods: {
    async saveCsv() {
      // let fileReader = new FileReader();
      // fileReader.readAsText(this.csvFile);
      // console.log(fileReader)
      // fileReader.onload = () => {
      //   this.isLimit = fileReader.result;
      //   console.log(this.isLimit)
      // };
      // console.log(this.isLimit.match(/\n/g)?.length)
      // if (this.isLimit.match(/\n/g)?.length < 152) {
      //   console.log('NO', this.isLimit)
      //   this.message = this.$t('organizations.limit');
      //   this.isLimit = '';
      //   fileReader = new FileReader();
      //   return;
      // }
      // console.log('ha pasado', this.isLimit)
      this.isLoadingButton = true;

      try {
        if (this.csvFile !== null) {
          await this.$store.dispatch("importFiles", {
            importType: "spaces/import",
            file: this.csvFile,
            forceUpdate: true,
            customName: "communities/simply",
            storedKey: "organizations",
            requestConfig: {
              mainTypeID: MainType.Organization,
            },
          });
          this.uploadFileVisible = false;
          this.notifySuccess(
            this.$t("organizations.import-csv.create-success")
          );
          this.$emit("close-modal", true);
        }
      } catch (error) {
        if (this.membersError !== "" && this.currentUser.appSumoID != null) {
          this.$bvModal.show("error-modal");
        } else {
          this.notifyError(this.$t("error-message.general-error"));
          this.$emit("close-modal", true);
        }
      } finally {
        this.isLoadingButton = false;
      }
    },
    downloadCsv() {
      let csv = "name;headline;description;email;phone;link;\r\n";
      this.csvTemplateOrganizations.forEach((row) => {
        csv += row.join(";");
        csv += "\r\n";
      });
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
        csv.replace(/,/g, ";")
      )}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = "CSV_template_organizations.csv";
      hiddenElement.click();
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    unixTimestamp(date) {
      const newDate = new Date(date);
      return Math.floor(newDate.getTime() / 1000);
    },
    setStart(data) {
      // this.itemInput.startDate = data;
      this.itemInput.startDateUnix = this.unixTimestamp(data);
    },
    setEnd(data) {
      // this.itemInput.endDate = data;
      this.itemInput.endDateUnix = this.unixTimestamp(data);
    },
    // Create item
    async handleCreateItem() {
      this.isSaving = true;
      if (!this.itemInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        let response = {};
        const startDate = this.itemInput.startDateUnix;
        const endDate = this.itemInput.endDateUnix;
        const file = {};
        if (this.image) file.logoURL = this.image;
        if (this.bannerURL) file.bannerURL = this.bannerURL;
        const requestConfig = {
          isEvent: this.itemType === "events" ? 1 : 0,
          isProject: this.itemType === "projects" ? 1 : 0,
          isChallenge: this.itemType === "challenges" ? 1 : 0,
          isCourse: this.itemType === "courses" ? 1 : 0,
          isOrganization: this.itemType === "organizations" ? 1 : 0,
          isService: this.itemType === "services" ? 1 : 0,
          name: this.itemInput.name,
          headline: this.itemInput.headline,
          locations: this.itemInput.locations,
          startDate: this.itemInput.startDate ? startDate : null,
          endDate: this.itemInput.endDate ? endDate : null,
          accessibility: this.accessibilityOption,
          modality: this.modalityOption,
          roomKey:
            this.modalityOption > 0 && this.itemInput.roomKey != null
              ? this.itemInput.roomKey
              : null,
          thumbnailURL: this.itemInput.thumbnailURL,
          published: this.fromOnboarding ? false : this.itemInput.isPublished,
          showCreated: this.itemInput.showCreated,
          applyTemplate: this.layoutTemplate,
          registrationURL: this.itemInput.registrationURL,
          joinURL: this.itemInput.joinURL,
          withShared: true,
          isMeeting: 0,
          type: this.typeOptions ? this.selectedTypeOption : null,
          ...(this.modalityOption === 1 || this.modalityOption === 2
            ? { capacity: parseInt(this.itemInput.capacity, 10) }
            : ""),
        };
        if (this.canCreateOrganization) {
          requestConfig.canCreateOrganization = this.canCreateOrganization;
        }
        response = await this.$store.dispatch("createItem", {
          item: {
            itemType: "communitiesOthers",
            storedKey: this.itemType,
            requestConfig,
          },
          file,
          noSet: this.set,
        });
        this.resetForm();
        this.isSaving = false;
        this.itemInput = {
          showCreated: true,
          isPublished: true,
          startDate: null,
          startDateUnix: null,
          endDate: null,
          endDateUnix: null,
          capacity: null,
          headline: {},
          name: {},
        };
        this.image = null;
        this.bannerURL = null;
        this.formError = null;
        this.$emit("create-item", true);
        this.notifySuccess(this.$t("success-message.general-success-create"));
        if (this.redirectAfterCreate) {
          window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
            "{subdomain}",
            "app"
          ).replace("{slug}", response.data.slug)}/backoffice/settings`;
        }
        return;
      } catch {
        if (this.membersError !== "" && this.currentUser.appSumoID != null) {
          this.$bvModal.show("error-modal");
        } else {
          this.notifyError(this.$t("error-message.general-error"));
        }
        this.isSaving = false;
      }
    },
    resetForm() {
      this.$emit("close-modal", false);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
.wizard-tap-content {
  background-color: transparent !important;
}
</style>
